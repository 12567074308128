/** @jsx jsx */
import { useState, useRef, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { arrowRight } from "react-icons-kit/feather/arrowRight"
import { arrowLeft } from "react-icons-kit/feather/arrowLeft"
import { Icon } from "react-icons-kit"
import { Flex, jsx } from "theme-ui"
import useInterval from "../hooks/useInterval"

export default function MySlider({
  children,
  slideStyle,
  nextArrow,
  nextArrowStyle,
  prevArrow,
  prevArrowStyle,
  activeSlideStyle,
  autoplay,
  duration,
}) {
  const [cindex, setCindex] = useState(0)
  const [offsetHeight, setOffsetHeight] = useState(false)
  const [reset, setReset] = useState(false)
  const wrapper = useRef()
  const list = children
  const nextIndex = cindex + 1 >= children.length ? 0 : cindex + 1
  const prevIndex = cindex - 1 >= 0 ? cindex - 1 : children.length - 1
  const currentSlide = list[cindex]

  const isSSR = () => typeof window === "undefined"

  const handleNext = () => setCindex(nextIndex)

  const handlePrev = () => setCindex(prevIndex)

  const handleResize = () =>
    setOffsetHeight(wrapper?.current?.offsetHeight || false)

  const handlePrevPress = () => {
    handlePrev()
    setReset(true)
  }
  const handleNextPress = () => {
    handleNext()
    setReset(true)
  }

  useInterval(() => autoplay && handleNext(), 5000, reset)

  useEffect(() => {
    if (!offsetHeight) handleResize()
    if (reset) setReset(false)
    if (!isSSR()) {
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [offsetHeight, reset])

  return (
    <Fragment>
      <div
        ref={wrapper}
        style={{
          position: "relative",
          overflow: "hidden",
          minHeight: "inherit",
        }}
      >
        <div
          style={{
            minHeight: "inherit",
            ...slideStyle,
            ...activeSlideStyle,
          }}
        >
          {currentSlide}
        </div>
        {offsetHeight ? (
          <Flex
            style={{
              position: "absolute",
              zIndex: 99,
              direction: "rtl",
              left: 0,
              right: 0,
              top: `${offsetHeight / 2 - 15}px`,
            }}
            sx={{ justifyContent: "space-between" }}
          >
            <div
              sx={prevArrowStyle}
              role="button"
              aria-label="prev arrow"
              tabIndex={0}
              onKeyPress={handlePrevPress}
              onClick={handlePrevPress}
            >
              {prevArrow || <Icon size={27} icon={arrowRight} />}
            </div>
            <div
              sx={nextArrowStyle}
              role="button"
              araia-label="next arrow"
              tabIndex={0}
              onKeyPress={handleNextPress}
              onClick={handleNextPress}
            >
              {nextArrow || <Icon size={27} icon={arrowLeft} />}
            </div>
          </Flex>
        ) : null}
      </div>
    </Fragment>
  )
}

MySlider.defaultProps = {
  duration: 5000,
  autoplay: false,
  nextArrow: null,
  prevArrow: null,
  nextArrowStyle: {},
  prevArrowStyle: {},
}

MySlider.propTypes = {
  autoplay: PropTypes.bool,
  duration: PropTypes.number,
  nextArrow: PropTypes.func,
  prevArrow: PropTypes.func,
  nextArrowStyle: PropTypes.shape(PropTypes.object.isRequired),
  prevArrowStyle: PropTypes.shape(PropTypes.object.isRequired),
}
