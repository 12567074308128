import { useStaticQuery, graphql } from "gatsby"

export default function useSliderQuery() {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/slider/" } }) {
        edges {
          node {
            frontmatter {
              description
              img {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              medImage: img {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              samllImage: img {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              title
              type
              lang
            }
          }
        }
      }
    }
  `)

  return allMarkdownRemark.edges
}
