/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import Wrapper from "./wrapper"
import VerticalLinesBackground from "./vertical-lines-background"
import Animate from "./animations"
import Img from "gatsby-image"

const ANIMATION_CONFIGS = [
  {
    heading: "fadeInUp",
    text: "fadeInUp",
  },
  {
    heading: "fadeInRight",
    text: "fadeInUp",
  },
  {
    heading: "fadeIn",
    text: "fadeInDown",
  },
]

export default function Slide({
  slidesLength,
  currentSlide,
  title,
  description,
  img,
}) {
  const duplicationAnimateConfig = [
    ...Array(Math.round(slidesLength / ANIMATION_CONFIGS.length)),
  ].map(() => ANIMATION_CONFIGS)

  const animationConfig = flat(duplicationAnimateConfig)

  return (
    <Styled.div
      sx={{
        minHeight: "inherit",
        backgroundColor: "#000",
        ">div": { animation: `fadeIn 0.5s` },
      }}
    >
      <Img
        style={{
          minHeight: "inherit",
          backgroundColor: "#000",

          height: "-webkit-fill-available",
        }}
        imgStyle={{ objectFit: "cover" }}
        fluid={img?.childImageSharp?.fluid}
      />
      <Styled.div
        sx={{
          position: "absolute",
          zIndex: 1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <VerticalLinesBackground opacity={0.1}>
          <Styled.div
            sx={{
              minHeight: 200,
              height: "calc(100vh - 122px)",
              display: "flex",
              alignItems: "center",
              width: "100vw",
            }}
          >
            <Wrapper>
              <div
                sx={{
                  maxWidth: 600,
                  p: 40,
                  px: [80, 80, 80, 40],
                }}
              >
                <Animate animationIn="fadeIn">
                  <div
                    sx={{
                      display: "flex",
                      color: "lightHeading",
                      alignItems: "center",
                      fontWeight: 600,
                      "&::before": {
                        opacity: 0.6,
                        content: '""',
                        display: ["none", "inline-block"],
                        width: 50,
                        height: "3px",
                        backgroundColor: "lightHeading",
                      },
                    }}
                  >
                    <span sx={{ mx: 2 }}>0{currentSlide}</span>
                    <span sx={{ opacity: 0.6 }}> / 0{slidesLength}</span>
                  </div>
                </Animate>
                <Styled.h2
                  sx={{
                    color: "lightHeading",
                    fontSize: ["1.8em", 40],
                    textShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
                    animation: `${
                      animationConfig[currentSlide - 1].heading
                    } 1s`,
                  }}
                >
                  {title}
                </Styled.h2>
                <Styled.p
                  sx={{
                    fontSize: 16,
                    textShadow: "0px 1px 2px rgba(0, 0, 0, 0.5)",
                    fontWeight: "400",
                    color: "white",
                    animation: `${animationConfig[currentSlide - 1].text} 1.3s`,
                  }}
                >
                  {description}
                </Styled.p>
              </div>
            </Wrapper>
          </Styled.div>
        </VerticalLinesBackground>
      </Styled.div>
    </Styled.div>
  )
}

function flat(array, depth = 1) {
  return array.reduce(function(flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) && depth > 1
        ? toFlatten.flat(depth - 1)
        : toFlatten
    )
  }, [])
}
