/** @jsx jsx */
import { jsx } from "theme-ui"

import MainLayout from "../layouts/main-layout"
import Seo from "../components/seo"
import MySlider from "../components/slider"
import Slide from "../components/slide"
import Icon from "react-icons-kit"
import { arrows_left } from "react-icons-kit/linea/arrows_left"
import { arrows_right } from "react-icons-kit/linea/arrows_right"
import useSliderQuery from "../hooks/useSliderQuery"
import useLanguage from "../hooks/useLanguage"

export default function HomeLayout() {
  const edges = useSliderQuery()
  const pageLang = useLanguage()
  const slideOfLang = edges.filter(
    ({ node }) => node.frontmatter.lang === pageLang
  )

  return (
    <MainLayout>
      <Seo title="Home" />
      <MySlider
        nextArrowStyle={arrowStyle}
        prevArrowStyle={arrowStyle}
        nextArrow={<Icon icon={arrows_left} size={45} />}
        prevArrow={<Icon icon={arrows_right} size={45} />}
        autoplay
      >
        {slideOfLang.map((slide, index) => (
          <Slide
            {...slide.node.frontmatter}
            key={index}
            slidesLength={5}
            currentSlide={index + 1}
          />
        ))}
      </MySlider>
    </MainLayout>
  )
}

const arrowStyle = {
  width: 84,
  height: 84,
  position: "relative",
  backgroundColor: "footer",
  color: "white",
  textAlign: "center",
  lineHeight: "84px",
  opacity: 0.8,
  "&:hover": {
    "&::after": {
      width: "100%",
      zIndex: 0,
    },
    svg: {
      zIndex: 1,
      position: "relative",
    },
  },
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "0%",
    backgroundColor: "primary",
    transition: "0.2s ease-in",
    zIndex: -1,
  },
}
